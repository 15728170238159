import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Spin } from 'antd';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import 'antd/dist/antd.css'
import 'react-quill/dist/quill.snow.css';

const IndexPage = lazy(() => import('./pages/IndexPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const DeviceListPage = lazy(() => import('./pages/DeviceListPage'));
const PluginDevPage = lazy(() => import('./pages/PluginDevPage'))
const PluginPage = lazy(() => import('./pages/PluginPage'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Suspense fallback={<div style={{padding: 40}}><Spin /></div>}>
            <Routes>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/' element={<IndexPage />}>
                    <Route path='plugin' element={<PluginPage />}></Route>
                    <Route path='plugin/dev' element={<PluginDevPage />}></Route>
                    <Route path='device/list' element={<DeviceListPage />}></Route>
                </Route>
            </Routes>
        </Suspense>
    </Router>
);